var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rc-view",staticStyle:{"min-height":"100vh"}},[(_vm.canShow)?_c('div',{staticClass:"pt-5 pb-5"},[_c('div',{staticClass:"rc-cardInvertir"},[_c('div',{staticClass:"cardInvertir__body"},[(_vm.showVista == 4)?_c('ConfirmacionKhipu',{attrs:{"tituloData":"Participación reservada con éxito","detalleProyecto":{
						id: _vm.investment.proyecto.id,
						proyecto: _vm.investment.proyecto.nombre,
						monto: this.$data.showMonto != null ? _vm.$options.filters.currency(this.$data.showMonto) : _vm.$options.filters.currency(_vm.gatewayTransaction.monto),
						metodo: this.$data.showPasarela,
					},"detalleInversion":[
						{ titulo: 'Tipo de crédito', dato: {valor: _vm.investment.proyecto.credito_tipo.valor, color: _vm.color.g1} },
						{ titulo: 'Tiempo del préstamo', dato: {valor: _vm.project.credito_tipo_id != 1 ? ((_vm.project.cuotas) + " Meses") : _vm.project.timeLimitText(), color: _vm.color.g1} },
						{ titulo: 'Fecha inicio de proyecto', dato: {valor: _vm.fechaPagoFormat, color: _vm.color.g1} },
						{ titulo: 'Fecha estimada de pago', dato: {valor: _vm.fechaInicioFormat, color: _vm.color.g1} } ],"costoInversion":[
						{ titulo: 'Derechos por participar', dato: {valor: _vm.$options.filters.currency(_vm.investment.derechos), color: _vm.color.g1} },
						{ titulo: 'Préstamo', dato: {valor: _vm.$options.filters.currency(_vm.investment.monto_prestamo), color: _vm.color.g1} },
						{ titulo: 'Monto total a inveritr (Préstamo + Derechos)', dato: {valor: _vm.$options.filters.currency(_vm.gatewayTransaction.monto), color: _vm.color.n1} }
					]}}):_vm._e(),(_vm.showVista == 2)?_c('ConfirmacionPagarDespues',{attrs:{"tituloData":"Dejaste tu transferencia pendiente","detalleProyecto":{
						id: _vm.investment.proyecto.id,
						proyecto: _vm.investment.proyecto.nombre,
						monto: this.$data.showMonto != null ? _vm.$options.filters.currency(this.$data.showMonto) : _vm.$options.filters.currency(_vm.gatewayTransaction.monto),
						metodo: this.$data.showPasarela,
					},"detalleInversion":[
						{ titulo: 'Tipo de crédito', dato: {valor: _vm.investment.proyecto.credito_tipo.valor, color: _vm.color.g1} },
						{ titulo: 'Tiempo del préstamo', dato: {valor: _vm.project.credito_tipo_id != 1 ? ((_vm.project.cuotas) + " Meses") : _vm.project.timeLimitText(), color: _vm.color.g1} },
						{ titulo: 'Fecha inicio de proyecto', dato: {valor: _vm.fechaPagoFormat, color: _vm.color.g1} },
						{ titulo: 'Fecha estimada de pago', dato: {valor: _vm.fechaInicioFormat, color: _vm.color.g1} } ],"costoInversion":[
						{ titulo: 'Derechos por participar', dato: {valor: _vm.$options.filters.currency(_vm.investment.derechos), color: _vm.color.g1} },
						{ titulo: 'Préstamo', dato: {valor: _vm.$options.filters.currency(_vm.investment.monto_prestamo), color: _vm.color.g1} },
						{ titulo: 'Monto total a inveritr (Préstamo + Derechos)', dato: {valor: _vm.$options.filters.currency(_vm.gatewayTransaction.monto), color: _vm.color.n1} }
					]}}):_vm._e(),(_vm.showVista == 1)?_c('ConfirmacionMontoTotal',{attrs:{"tituloData":"Participación reservada con éxito","detalleProyecto":{
						id: _vm.investment.proyecto.id,
						proyecto: _vm.investment.proyecto.nombre,
						monto: this.$data.showMonto != null ? _vm.$options.filters.currency(this.$data.showMonto) : _vm.$options.filters.currency(_vm.gatewayTransaction.monto),
						metodo: this.$data.showPasarela,
					},"detalleInversion":[
						{ titulo: 'Tipo de crédito', dato: {valor: _vm.investment.proyecto.credito_tipo.valor, color: _vm.color.g1} },
						{ titulo: 'Tiempo del préstamo', dato: {valor: _vm.project.credito_tipo_id != 1 ? ((_vm.project.cuotas) + " Meses") : _vm.project.timeLimitText(), color: _vm.color.g1} },
						{ titulo: 'Fecha inicio de proyecto', dato: {valor: _vm.fechaPagoFormat, color: _vm.color.g1} },
						{ titulo: 'Fecha estimada de pago', dato: {valor: _vm.fechaInicioFormat, color: _vm.color.g1} } ],"costoInversion":[
						{ titulo: 'Derechos por participar', dato: {valor: _vm.$options.filters.currency(_vm.investment.derechos), color: _vm.color.g1} },
						{ titulo: 'Préstamo', dato: {valor: _vm.$options.filters.currency(_vm.investment.monto_prestamo), color: _vm.color.g1} },
						{ titulo: 'Monto total a inveritr (Préstamo + Derechos)', dato: {valor: _vm.$options.filters.currency(_vm.gatewayTransaction.monto), color: _vm.color.n1} }
					]}}):_vm._e(),(_vm.showVista == 3)?_c('ConfirmacionAbono',{attrs:{"tituloData":"Participación reservada con éxito","detalleProyecto":{
						id: _vm.investment.proyecto.id,
						proyecto: _vm.investment.proyecto.nombre,
						monto: this.$data.showMonto != null ? _vm.$options.filters.currency(this.$data.showMonto) : _vm.$options.filters.currency(_vm.gatewayTransaction.monto),
						metodo: this.$data.showPasarela,
					},"detalleInversion":[
						{ titulo: 'Tipo de crédito', dato: {valor: _vm.investment.proyecto.credito_tipo.valor, color: _vm.color.g1} },
						{ titulo: 'Tiempo del préstamo', dato: {valor: _vm.project.credito_tipo_id != 1 ? ((_vm.project.cuotas) + " Meses") : _vm.project.timeLimitText(), color: _vm.color.g1} },
						{ titulo: 'Fecha inicio de proyecto', dato: {valor: _vm.fechaPagoFormat, color: _vm.color.g1} },
						{ titulo: 'Fecha estimada de pago', dato: {valor: _vm.fechaInicioFormat, color: _vm.color.g1} } ],"costoInversion":[
						{ titulo: 'Derechos por participar', dato: {valor: _vm.$options.filters.currency(_vm.investment.derechos), color: _vm.color.g1} },
						{ titulo: 'Préstamo', dato: {valor: _vm.$options.filters.currency(_vm.investment.monto_prestamo), color: _vm.color.g1} },
						{ titulo: 'Monto total a inveritr (Préstamo + Derechos)', dato: {valor: _vm.$options.filters.currency(_vm.gatewayTransaction.monto), color: _vm.color.n1} }
					]}}):_vm._e()],1)])]):_c('div',[_c('ShowLoading',{attrs:{"tituloLoading":"Cargando"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }