<template>
    <div class="rc-msjReserva">
        <v-icon>error</v-icon> {{textoData}}
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		props: {
			textoData: String,
        },
	})
	export default class MensajeRecuadro extends Vue {}
</script>

<style lang="scss" scoped>
   .rc-msjReserva{
		min-height: 3rem;
		background-color: $rc-other4;
		border-radius: 5px;
		color: $rc-prim-g2;
		font-weight: 600;
		font-size: 1rem;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		width: fit-content;
		padding: 1rem 2rem 1rem 2rem;
		i{
			color: $rc-prim-n1;
			margin-right: 1rem;
		}
	}
    
    @each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				
			}@else if $breakpoint == "small-to-medium"{
			    
			}@else if $breakpoint == "small"{
                
			}
		}
	}
</style>
